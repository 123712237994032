import '../../../../../styles.scss';
import React, { useEffect, useState } from 'react';
import { AWS_STATIC_ASSET_HOST } from '../../../../consts/env.consts';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { setFirstClick } from '../../../../helpers/firstClick';
import useMobileScreen from '../../hooks/useMobileScreen';
import BlogHomeSSR from '../../../../pages/blog';
import '../../../../helpers/firebase';

// eslint-disable-next-line no-undef
__webpack_public_path__ = `${AWS_STATIC_ASSET_HOST}/packs/`;

const client = new ApolloClient({
    uri: '/graphql',
    cache: new InMemoryCache(),
});

const BlogHome =
    ({ blogCategoriesSSR, blogPostsSSR, totalPages }, { pathname, search }) =>
    () => {
        const isMobileSSR = useMobileScreen();

        useEffect(() => {
            setFirstClick();
        }, []);

        return (
            <ApolloProvider client={client}>
                <BlogHomeSSR
                    {...{
                        search,
                        pathname,
                        blogCategoriesSSR,
                        blogPostsSSR,
                        isMobileSSR,
                        useStateSSR: useState,
                        totalPages,
                    }}
                />
            </ApolloProvider>
        );
    };

export default BlogHome;
